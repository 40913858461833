body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* fixes EUI's background-color:#fafbfd */
html {
  background-color: #ffffff;
}
